 

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import TrackVisibility from 'react-on-screen';
import { graphql } from 'gatsby';
import H2 from '../src/components/Titles/TitleH2/TitleH2';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Layout from '../src/components/Layout/Layout';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import SEO from '../src/helpers/seo';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import Ymir from '../src/components/Ymir/Ymir';
import tt from '../src/helpers/translation';
import Hod from '../src/components/Hod/Hod';
import './politica_privacidad.scss';

export const pageQuery = ({ data, pathContext: { locale } }) => graphql`
  query politica_privacidad($locale: String!) {
    allPrivacidadTitleHeader(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
        }
      }
    }
    allPrivacidadImageHeader(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allPrivacidadSeoData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allPrivacidadMainTextBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          body
        }
      }
    }
    allPrivacidadBreadcrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
        }
      }
    }
    allPrivacidadRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
  }
`;

class PoliticaPriv extends Component {
  state = {};

  render() {
    const heimdallData = {
      name: this.props.data.allPrivacidadTitleHeader.edges[0].node.title,
      image: {
        url: this.props.data.allPrivacidadImageHeader.edges[0].node.localImage.childImageSharp
          .fluid,
        alt: this.props.data.allPrivacidadImageHeader.edges[0].node.alt,
      },
    };

    const ymirData = {
      cards: [
        {
          title: tt('HORARIOS Y CALENDARIO', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('/horarios-calendario', this.props.pageContext.locale),
          icon: 'calendar',
        },
        {
          title: tt('CÓMO LLEGAR AL RESORT', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('/destino/planificar/como-llegar-a-portaventura', this.props.pageContext.locale),
          icon: 'LOCATION',
        },
        {
          title: tt('DESCUENTOS EN ENTRADAS ONLINE', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('/promociones', this.props.pageContext.locale),
          icon: 'Methodofpayment',
        },
      ],
    };

    return (
      <Layout layoutFromOld  locale={this.props.pageContext.locale} pageName={this.props.pageContext.pageName}>
        <SEO
          title={this.props.data.allPrivacidadSeoData.edges[0].node._0.title}
          description={this.props.data.allPrivacidadSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <Hod
          data={this.props.data.allPrivacidadRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={this.props.data.allPrivacidadImageHeader.edges[0].node.image}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="politica-container">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allPrivacidadBreadcrumbBlock.edges[0].node.name}
              />
              <BlueArrow />
              <H2 data={this.props.data.allPrivacidadMainTextBlock.edges[0].node} />
              <div
                className="inner-text"
                dangerouslySetInnerHTML={{
                  __html: this.props.data.allPrivacidadMainTextBlock.edges[0].node.body,
                }}
              />
              <Ymir data={ymirData} />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default PoliticaPriv;
